.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    /* gap: 366px; */

    /* width: 840px; */
    width: 100%;
    height: 40px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.mainHeading{
    /* width: 180px; */
    /* height: 51%; */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height, or 138% */

    color: #000000;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.buttonGroup{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    gap: 1rem;
    /* gap: 10%; */

    height: 100%;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.headerButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    /* border-radius: 6px; */

    /* width: 186px; */
    height: 100%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;

}

.messageContainer{
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 10%;

    width: 100%;
    min-height: 200px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    /* align-self: stretch; */

}

.formControlStyle{
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 10%;
    /* margin: 164px 380px 50px 50px; */
    /* width: 510px;
    height: 473px; */
    width: 100%;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 30px;
    /* margin: 164px 380px 50px 50px; */
    /* width: 510px;
    height: 473px; */
    width: 50%;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.messageType{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4%;

    /* height: 50px; */


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.subHeading{
    /* width: 64px;
    height: 22px; */
    /* width: auto; */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;

    /* identical to box height, or 220% */

    color: rgba(0, 0, 0, 0.65);

}

.notificationTypes{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    /* gap: 12px; */
    gap: 1vw;

    /* height: 24px; */


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0
}

.radioButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;

    /* width: 80px; */
    /* height: 24px; */


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    /* Black 85 */
    color: rgba(0, 0, 0, 0.85);


}

.messageTitle{
    /* height: 63px; */
    /* width: 100%; */
    /* border-radius: 4px; */

/* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.titleFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* gap: 4px; */

    width: 100%;
    height: 40px;
    left: 0px;
    top: 22.23px;


}

.textField{
    height: 5vh;
    width: 100%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.85);
}

.richTextArea{
    width: 100%;
    /* height: 195px; */

    margin-top: 1%;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
}

.schedule{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    gap: 3%;

    /* width: 447px; */
    height: 63px;


    /* Inside auto layout */
    flex: none;
    order: 3;
    flex-grow: 0;
}

.checkbox{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    /* gap: 8px; */

    /* width: 155px; */
    /* height: 44px; */


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.checkboxFrame{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 5%;

    /* width: 24px; */
    height: 24px;

    /* White */
    background: #FFFFFF;
    border-radius: 2px;

}

label{
    /* width: 103px; */
    height: 22px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    /* identical to box height, or 157% */

    /* Black 85 */
    color: rgba(0, 0, 0, 0.85);


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.selectBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
    /* gap: 4px; */

    /* position: absolute; */
    width: 100%;
    /* height: 40px; */
    /* left: 0px; */
    /* top: 22.23px; */
}

.listContainer{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 0px;
    gap: 34px;
    width: 100%;
    min-height: 200px;
    margin-top: 50px;

    flex: none;
    order: 1;
    flex-grow: 0;

}

.notificationTypeFrame{
    align-items: flex-end;
    padding: 0px;
    /* gap: 5px; */

    /* width: 56px; */
    height: 22px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    /* cursor: pointer;
    pointer-events: auto; */
    /* color: #2034F1; */
}
.notificationTypeFrame:active{
    color: #4B2AF1;
}


.headerMessageContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 135px;
}

.searchBoxContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding-right: 10;
    padding-left: 10;

}

.searchFilterContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    /* gap: 10%; */
}

.notificationListGroup{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;

    flex: none;
    order: 2;
    flex-grow: 0;
}

.tableRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10%;

    /* width: 100%; */
    height: 62px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.titleStatusGroup{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 2%;

    width: 75%;
    height: 62px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.titleData{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;

    width: 50%;
    height: 62px;

}
.statusData{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;

    width: 30%;
    height: 54px;

}

.titleStyle{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.descriptionStyle{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 1;
    flex-grow: 0;
}

.actionButtonGroups{
    display: flex;
    flex-direction: row;
    justify-items: flex-end;
    align-items: flex-end;
    /* padding: 10px 8px; */
    gap: 4%;

    width: 20%;
    height: 42px;

}

/* .viewAction{
    flex-direction: row;
    flex: none;
    order: 0;
}
.editAction{
    flex-direction: row;
    flex: none;
    order: 1;
} */

tr{
    border-bottom: 1px solid #D9D8DD;
    margin-top: 7px;
}

.details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    /* gap: 56px; */
    gap:5%;

    /* position: absolute; */
    width: 100%;
    height: 120px;
    margin-top: 25px;
    /* left: 218px; */
    /* top: 289px; */
}

.pagination{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
}