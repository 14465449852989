.right-frame{
    display: flex;
    flex-direction: column;
    justify-content: right;
    /* align-items: flex-end; */
    padding: 0px;
    gap: 24px;
    /* height: 302px; */
    
    width: 40%;

    flex: none;
    order: 1;
    flex-grow: 0;
   
}

.target-audience{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 6px;

    /* width: 280px; */
    width: 100%;
    /* height: 91px; */


    /* Inside auto layout */
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.heading{
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    align-items: flex-start;
  
    color: #000000;
}

.select-frame{
    /* width: 280px; */
    width: 100%;
    height: 63px;
    margin-bottom: 20px;
    border-radius: 4px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.display-target{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 22px;
    /* width: 170px; */
    height: 50px;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.branch-name{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;

    /* identical to box height, or 180% */

    /* Black 85 */
    color: rgba(0, 0, 0, 0.85);
}

.user-group-frame{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    width: 100%;

}

.user-single-frame{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;

    width: 82px;
    gap: 10px;
    background: #F0F0F0;
    border-radius: 6px;
   
}

.user-group-name{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    /* identical to box height, or 180% */

    color: rgba(0, 0, 0, 0.4);


}

.user-close-icon{

    cursor: pointer;
    transform: translateX(-10%);
    transform: translateY(30%);
}

.close-icon{
    pointer-events: none;

}