.messageType{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    /* width: 30%; */
    height: 100px;

    flex: none;
    order: 0;
    flex-grow: 0;

}

.subHeading{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: rgba(0, 0, 0, 0.85);

}

.titleDetail{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    
    color: rgba(0, 0, 0, 0.85);

}

.titleFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;

    /* width: 86px; */
    height: 40px;
}

.analyticsFrame{
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 1vw;

    /* width: 60%; */
    height: 120px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.analyticContent{
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 23px 43px;
    gap: 10px;

    /* width: 201px; */
    height: 120px;

    /* Stroke */

    border: 1px solid #D9D8DD;
    border-radius: 6px;

    /* Inside auto layout */

    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.subheading_2{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    /* Black 85 */

    color: rgba(0, 0, 0, 0.85);


}

.numberData{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;

    /* Primary */

    color: #2034F1;
}

.branchGroupFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 25px;
    margin-top: 25px;

    /* position: absolute;
    width: 308px; */
    /* height: 119px; */
    /* left: 218px;
    top: 434px; */
    flex: none;
    order: 1;

}

.singlebranchFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;

    /* width: 226px; */
    height: 47px;

}