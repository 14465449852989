.popover{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;

    height: 116px;
    width: 556px;

    background: #FFFFFF;
    border-radius: 6px;

}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    margin-bottom: 20px;

    width: 100%;
    height: 22px;

}

.heading{
    height: 22px;

    font-family: 'Open-Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.closeButton{
    height: 20px;
    cursor: pointer;

    flex: none;
    order: 1;
    flex-grow: 0;
}
.closeButton:hover{
    background-color: #c7c4c4;
}