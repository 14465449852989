body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Open Sans'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F7F7F7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ck-editor__editable {
  min-height: 155px !important;
  /* max-height: 195px !important; */
  border-radius: 6px;
}


 .App {
  /* text-align: center; */
  background: #F7F7F7;
}
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.createNotification_header__39Ey6{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    /* gap: 366px; */

    /* width: 840px; */
    width: 100%;
    height: 40px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.createNotification_mainHeading__L30io{
    /* width: 180px; */
    /* height: 51%; */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height, or 138% */

    color: #000000;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.createNotification_buttonGroup__29GCH{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    grid-gap: 1rem;
    gap: 1rem;
    /* gap: 10%; */

    height: 100%;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.createNotification_headerButton__3yxnA{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    /* border-radius: 6px; */

    /* width: 186px; */
    height: 100%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;

}

.createNotification_messageContainer__13s0D{
    display: flex;
    flex-direction: row;
    padding: 0px;
    grid-gap: 10%;
    gap: 10%;

    width: 100%;
    min-height: 200px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    /* align-self: stretch; */

}

.createNotification_formControlStyle__1fqAz{
    display: flex;
    flex-direction: row;
    padding: 0px;
    grid-gap: 10%;
    gap: 10%;
    /* margin: 164px 380px 50px 50px; */
    /* width: 510px;
    height: 473px; */
    width: 100%;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.createNotification_left__msGDu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 30px;
    gap: 30px;
    /* margin: 164px 380px 50px 50px; */
    /* width: 510px;
    height: 473px; */
    width: 50%;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.createNotification_messageType__1_3_Y{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 4%;
    gap: 4%;

    /* height: 50px; */


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.createNotification_subHeading__3Dhwc{
    /* width: 64px;
    height: 22px; */
    /* width: auto; */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;

    /* identical to box height, or 220% */

    color: rgba(0, 0, 0, 0.65);

}

.createNotification_notificationTypes__31ofl{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    /* gap: 12px; */
    grid-gap: 1vw;
    gap: 1vw;

    /* height: 24px; */


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0
}

.createNotification_radioButton__1Q9Eh{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    grid-gap: 6px;
    gap: 6px;

    /* width: 80px; */
    /* height: 24px; */


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    /* Black 85 */
    color: rgba(0, 0, 0, 0.85);


}

.createNotification_messageTitle__xoLU7{
    /* height: 63px; */
    /* width: 100%; */
    /* border-radius: 4px; */

/* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.createNotification_titleFrame__1la-F{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* gap: 4px; */

    width: 100%;
    height: 40px;
    left: 0px;
    top: 22.23px;


}

.createNotification_textField__1eHb7{
    height: 5vh;
    width: 100%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.85);
}

.createNotification_richTextArea__2e7Oh{
    width: 100%;
    /* height: 195px; */

    margin-top: 1%;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
}

.createNotification_schedule__1fqyu{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    grid-gap: 3%;
    gap: 3%;

    /* width: 447px; */
    height: 63px;


    /* Inside auto layout */
    flex: none;
    order: 3;
    flex-grow: 0;
}

.createNotification_checkbox__3rwgj{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    /* gap: 8px; */

    /* width: 155px; */
    /* height: 44px; */


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.createNotification_checkboxFrame__1LJB2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 5%;
    gap: 5%;

    /* width: 24px; */
    height: 24px;

    /* White */
    background: #FFFFFF;
    border-radius: 2px;

}

label{
    /* width: 103px; */
    height: 22px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    /* identical to box height, or 157% */

    /* Black 85 */
    color: rgba(0, 0, 0, 0.85);


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.createNotification_selectBox__3OmqU{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
    /* gap: 4px; */

    /* position: absolute; */
    width: 100%;
    /* height: 40px; */
    /* left: 0px; */
    /* top: 22.23px; */
}

.createNotification_listContainer__2kX9o{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 0px;
    grid-gap: 34px;
    gap: 34px;
    width: 100%;
    min-height: 200px;
    margin-top: 50px;

    flex: none;
    order: 1;
    flex-grow: 0;

}

.createNotification_notificationTypeFrame__2qHfB{
    align-items: flex-end;
    padding: 0px;
    /* gap: 5px; */

    /* width: 56px; */
    height: 22px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    /* cursor: pointer;
    pointer-events: auto; */
    /* color: #2034F1; */
}
.createNotification_notificationTypeFrame__2qHfB:active{
    color: #4B2AF1;
}


.createNotification_headerMessageContainer__FvY1i{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    grid-gap: 135px;
    gap: 135px;
}

.createNotification_searchBoxContainer__2h5ly{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    padding-right: 10;
    padding-left: 10;

}

.createNotification_searchFilterContainer__2ECxu{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    /* gap: 10%; */
}

.createNotification_notificationListGroup__1s3iY{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;

    flex: none;
    order: 2;
    flex-grow: 0;
}

.createNotification_tableRow__2aXhe{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    grid-gap: 10%;
    gap: 10%;

    /* width: 100%; */
    height: 62px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.createNotification_titleStatusGroup__1kO9m{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 2%;
    gap: 2%;

    width: 75%;
    height: 62px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.createNotification_titleData__2Uaa4{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;

    width: 50%;
    height: 62px;

}
.createNotification_statusData__1mXCq{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;

    width: 30%;
    height: 54px;

}

.createNotification_titleStyle__1Wkmv{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.createNotification_descriptionStyle__1Uk6V{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: rgba(0, 0, 0, 0.85);

    flex: none;
    order: 1;
    flex-grow: 0;
}

.createNotification_actionButtonGroups__1D0g5{
    display: flex;
    flex-direction: row;
    justify-items: flex-end;
    align-items: flex-end;
    /* padding: 10px 8px; */
    grid-gap: 4%;
    gap: 4%;

    width: 20%;
    height: 42px;

}

/* .viewAction{
    flex-direction: row;
    flex: none;
    order: 0;
}
.editAction{
    flex-direction: row;
    flex: none;
    order: 1;
} */

tr{
    border-bottom: 1px solid #D9D8DD;
    margin-top: 7px;
}

.createNotification_details__3QpG-{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    /* gap: 56px; */
    grid-gap:5%;
    gap:5%;

    /* position: absolute; */
    width: 100%;
    height: 120px;
    margin-top: 25px;
    /* left: 218px; */
    /* top: 289px; */
}

.createNotification_pagination__39Oxe{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
}
.right-frame{
    display: flex;
    flex-direction: column;
    justify-content: right;
    /* align-items: flex-end; */
    padding: 0px;
    grid-gap: 24px;
    gap: 24px;
    /* height: 302px; */
    
    width: 40%;

    flex: none;
    order: 1;
    flex-grow: 0;
   
}

.target-audience{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    grid-gap: 6px;
    gap: 6px;

    /* width: 280px; */
    width: 100%;
    /* height: 91px; */


    /* Inside auto layout */
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.heading{
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    align-items: flex-start;
  
    color: #000000;
}

.select-frame{
    /* width: 280px; */
    width: 100%;
    height: 63px;
    margin-bottom: 20px;
    border-radius: 4px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.display-target{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 22px;
    /* width: 170px; */
    height: 50px;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.branch-name{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;

    /* identical to box height, or 180% */

    /* Black 85 */
    color: rgba(0, 0, 0, 0.85);
}

.user-group-frame{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 6px;
    gap: 6px;
    width: 100%;

}

.user-single-frame{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;

    width: 82px;
    grid-gap: 10px;
    gap: 10px;
    background: #F0F0F0;
    border-radius: 6px;
   
}

.user-group-name{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    /* identical to box height, or 180% */

    color: rgba(0, 0, 0, 0.4);


}

.user-close-icon{

    cursor: pointer;
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
}

.close-icon{
    pointer-events: none;

}
.wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5%;
    min-width: 65%;
    min-height: 50vh;
    margin: 8% 10% 2.9%;
    background: #FFFFFF;
}
.filterModal_popover__2q2z0{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    grid-gap: 10px;
    gap: 10px;

    height: 116px;
    width: 556px;

    background: #FFFFFF;
    border-radius: 6px;

}

.filterModal_header__1x8gc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 20px;

    width: 100%;
    height: 22px;

}

.filterModal_heading__1AMeu{
    height: 22px;

    font-family: 'Open-Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.filterModal_closeButton__3plUF{
    height: 20px;
    cursor: pointer;

    flex: none;
    order: 1;
    flex-grow: 0;
}
.filterModal_closeButton__3plUF:hover{
    background-color: #c7c4c4;
}
.messageType{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 20px;
    gap: 20px;

    /* width: 30%; */
    height: 100px;

    flex: none;
    order: 0;
    flex-grow: 0;

}

.subHeading{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: rgba(0, 0, 0, 0.85);

}

.titleDetail{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    
    color: rgba(0, 0, 0, 0.85);

}

.titleFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 5px;
    gap: 5px;

    /* width: 86px; */
    height: 40px;
}

.analyticsFrame{
    display: flex;
    flex-direction: row;
    padding: 0px;
    grid-gap: 1vw;
    gap: 1vw;

    /* width: 60%; */
    height: 120px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.analyticContent{
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 23px 43px;
    grid-gap: 10px;
    gap: 10px;

    /* width: 201px; */
    height: 120px;

    /* Stroke */

    border: 1px solid #D9D8DD;
    border-radius: 6px;

    /* Inside auto layout */

    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.subheading_2{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    /* Black 85 */

    color: rgba(0, 0, 0, 0.85);


}

.numberData{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;

    /* Primary */

    color: #2034F1;
}

.branchGroupFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 25px;
    gap: 25px;
    margin-top: 25px;

    /* position: absolute;
    width: 308px; */
    /* height: 119px; */
    /* left: 218px;
    top: 434px; */
    flex: none;
    order: 1;

}

.singlebranchFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 5px;
    gap: 5px;

    /* width: 226px; */
    height: 47px;

}
